import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Image from 'components/image';
import SEO from 'components/seo';
import Links from 'components/links';
import { LinkLabel } from 'utils/constants';
import { postMap, slugify } from 'utils/post-map';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Hitting a Plateau Should Be Like Greeting An Old Friend`}</h1>
    <p><strong parentName="p">{`All Access Pass - October 7, 2020`}</strong></p>
    <p><strong parentName="p">{`We’ve been making this shit look easy so far.`}</strong></p>
    <p>{`I wanna raise `}{`$`}{`1M from investors for my fund?`}</p>
    <p>{`Bada-bing-bada-boom. We raised `}<strong parentName="p">{`double`}</strong>{` that within 10 days.`}</p>
    <p><strong parentName="p">{`Every experiment we tried worked so far:`}</strong></p>
    <ul>
      <li parentName="ul">{`The Twitter thunderclap`}</li>
      <li parentName="ul">{`The Brex followup technique`}</li>
      <li parentName="ul">{`The job search to find the perfect operator to run the fund.`}</li>
    </ul>
    <p>{`It’s like we were fishing with rigged bait. We threw it into the ocean and were
catching 90 lb marlins on our first try. (I’ve never been fishing, so I
apologize if that makes no sense).`}</p>
    <p>{`Anyways, you get the point - everything was working. It was almost working too
easily.`}</p>
    <p>{`And it was giving you a skewed perspective of what I was trying to provide with
the All Access Pass (a real world view into the day-to-day of starting things
from scratch).`}</p>
    <p>{`Well this week, that streak of everything hitting on the first try came to a
crashing halt!`}</p>
    <p><strong parentName="p">{`So what exactly happened this week?`}</strong></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Monday -`}</strong>{` Okay, let’s kick some ass this week. I’ll spin up a landing page
and make some facebook ads and everything will work no prob... I’m a growth
god.`}</li>
      <li parentName="ul"><strong parentName="li">{`Tuesday`}</strong>{` - Hmm.. email list isn't growing. I’ll just tweak a couple things
and then it'll work`}</li>
      <li parentName="ul"><strong parentName="li">{`Wednesday`}</strong>{` - fuck. still not growing.`}</li>
    </ul>
    <p><strong parentName="p"><em parentName="strong">{`We have all kinds of words for shit like this.`}</em></strong>{` “setback” , “obstacle,”
“wall”, etc..`}</p>
    <p>{`It feels like you hit a wall. You can’t move forward. You got blocked.`}</p>
    <p>{`But in reality, it’s not a wall. It’s a plateau.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "358px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "73.46368715083798%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAd5RYxh//8QAGBAAAwEBAAAAAAAAAAAAAAAAAAEREBL/2gAIAQEAAQUCKKjvWf/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABcQAAMBAAAAAAAAAAAAAAAAAAABISD/2gAIAQEABj8CIV4//8QAHRAAAgIBBQAAAAAAAAAAAAAAAAERIVEQMUFxgf/aAAgBAQABPyGd5LLtyhMjwio2hVl6f//aAAwDAQACAAMAAAAQ4M//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAaEAEBAQEBAQEAAAAAAAAAAAABEQAhMWGR/9oACAEBAAE/EDhQAuDejwUL+4Q9V5IhkCYdSCOikEnzDQfN/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Hitting plateau",
          "title": "Hitting plateau",
          "src": "/static/809ecee49c35e1de57ef8fb4e9d7b524/af061/hitting-plateau.jpg",
          "srcSet": ["/static/809ecee49c35e1de57ef8fb4e9d7b524/af061/hitting-plateau.jpg 358w"],
          "sizes": "(max-width: 358px) 100vw, 358px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`When I was younger, I used to overreact to these types of situations. Whenever
I'd hit a plateau and we weren’t making progress...I’d feel so worthless.
`}<strong parentName="p"><em parentName="strong">{`Like why can’t I make this work?`}</em></strong></p>
    <p>{`And the more successful people I surrounded myself with, the more worthless I
felt when this would happen.`}</p>
    <p>{`As I’ve mentioned in the Master, Stresser, Dabbler framework
(`}<a parentName="p" {...{
        "href": "https://www.facebook.com/TonyRobbins/videos/10152682303459060"
      }}><strong parentName="a">{`thanks to tony robbins for this`}</strong></a>{`).
I want to be a Master. And a master `}<strong parentName="p">{`expects`}</strong>{` plateaus to occur (they always
happen when pursuing growth), and `}<strong parentName="p">{`greets them like an old friend`}</strong>{`.`}</p>
    <p><em parentName="p">{`Ah, mr plateau. I thought I’d be seeing you soon!`}</em></p>
    <p>{`Over time, I’ve `}<strong parentName="p">{`programmed`}</strong>{` my brain to react to certain situations. I think
of these like tiny recipes. If my mom wants to make peach cobbler. She doesn’t
sit down and try to figure it out every single time. She goes to her recipe,
adds the ingredients, and boom she gets a predictably delicious result.`}</p>
    <p>{`That’s how I want my brain to work. I want recipes to get the results I want.`}</p>
    <p>{`So when I wanna “break through a plateau”, I go to my recipe:`}</p>
    <p><strong parentName="p">{`The Plateau Breakthrough Recipe`}</strong></p>
    <ol>
      <li parentName="ol">{`First, relax. Plateaus are `}<strong parentName="li">{`always`}</strong>{` a part of growth curves. This is
normal.`}</li>
      <li parentName="ol">{`Start with getting in the right “state” of mind. I make better decisions, and
am more creative when feeling loose, confident, happy. Not frustrated, tired,
or stressed.`}</li>
    </ol>
    <p><strong parentName="p">{`How to change my mental state? The Three M's`}</strong></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Music`}</strong>{` - duh.`}</li>
      <li parentName="ul"><strong parentName="li">{`Movement`}</strong>{` - pushups, run, swim, jumping jacks etc.. for 2-5 mins. The
fastest way to change how you feel is through rapid physical change (eg. ice
plunge, windsprints, etc..)`}</li>
      <li parentName="ul"><strong parentName="li">{`M`}</strong>{` eaning - rather than “shit, this isn’t working”, reframe it.`}</li>
    </ul>
    <p><strong parentName="p">{`For example, I could reframe the meaning behind what’s not working as:`}</strong></p>
    <ul>
      <li parentName="ul">{`This is an opportunity to try something new`}</li>
      <li parentName="ul">{`This is pushing me to get more creative`}</li>
      <li parentName="ul">{`This is a chance to sharpen up my skills and learn more`}</li>
    </ul>
    <p><strong parentName="p">{`..and my personal favorite because it works no matter how bad the situation:`}</strong></p>
    <p><strong parentName="p">{`This is going to make for a helluva story later.`}</strong></p>
    <p><strong parentName="p">{`For movement, I can go outside:`}</strong></p>
    <p>{`My trainer calls this being in a `}<strong parentName="p"><em parentName="strong">{`phenomenal`}</em></strong>{` state of mind. It takes < 5
mins to make that shift. I can do it in less than 10 seconds now because I’ve
done it so many times.`}</p>
    <p><strong parentName="p">{`Here’s me doing a quick 1 min state change today in my backyard (click the
image below if you wanna watch me do some pushups haha)`}</strong></p>
    <p><a parentName="p" {...{
        "href": "https://vimeo.com/466017249/62c1d3540e"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1125px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "216.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAArABQDASIAAhEBAxEB/8QAGQAAAwEBAQAAAAAAAAAAAAAAAAIFAQQD/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECA//aAAwDAQACEAMQAAABnpYTC4hWLmjzm2nFEcew/QVQjAf/xAAeEAEAAgEEAwAAAAAAAAAAAAABABICAwQRIRMUMf/aAAgBAQABBQI2yzPQq0nIQcc567KjMOIHTrWR4nklsZcC5D6nU//EABcRAAMBAAAAAAAAAAAAAAAAAAAQEQH/2gAIAQMBAT8BmEVf/8QAGBEAAgMAAAAAAAAAAAAAAAAAABIBEBH/2gAIAQIBAT8BeRrwU//EAB8QAAICAQQDAAAAAAAAAAAAAAABETEQAiAhgTJhof/aAAgBAQAGPwLlnl8LJlkPS+yyaG3L9RhJd54aLRa2f//EAB8QAQACAgEFAQAAAAAAAAAAAAEAESExYRBxgdHh8f/aAAgBAQABPyGsUB4gEWbiv5iO2PO5lQxuOM+IgdHJEC9jWioVbu5UChNtwcrblTYyjT7M9px+hBYhGB0//9oADAMBAAIAAwAAABA82zPvH//EABkRAAIDAQAAAAAAAAAAAAAAAAARARAxUf/aAAgBAwEBPxCcqMfohH//xAAZEQADAAMAAAAAAAAAAAAAAAAAAREQIUH/2gAIAQIBAT8Q2lEXSonD/8QAHxABAQABBAMBAQAAAAAAAAAAAREAITFBkVFhgRBx/9oACAEBAAE/EDILNl0/lw6kFrEO8usSDvzxEvuM1+Zcs6DofcGLq+Scj0QspMtxAJ9S7OHgSKl29ZVqoAx1plLUt+c2Wi25uXoCtYauPkD1cW6dfCAlFy+g3z+f/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Shaan's workout",
            "title": "Shaan's workout",
            "src": "/static/c464d7a3a3239ad96e6d562997e20006/551ac/shaan-workout.jpg",
            "srcSet": ["/static/c464d7a3a3239ad96e6d562997e20006/a6407/shaan-workout.jpg 400w", "/static/c464d7a3a3239ad96e6d562997e20006/a24e6/shaan-workout.jpg 800w", "/static/c464d7a3a3239ad96e6d562997e20006/551ac/shaan-workout.jpg 1125w"],
            "sizes": "(max-width: 1125px) 100vw, 1125px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span></a></p>
    <p><strong parentName="p">{`Once I’m there, I start asking my brain questions:`}</strong></p>
    <ol>
      <li parentName="ol">{`What does the data actually say is happening?`}</li>
      <li parentName="ol">{`What is the stupidly obvious thing I haven’t tried yet?`}</li>
      <li parentName="ol">{`What would a friend or mentor of mine do in this scenario?`}</li>
      <li parentName="ol">{`If I was going to make a simple tweak to the current product, what would I
change?`}</li>
      <li parentName="ol">{`Let’s look at the first-mile of the product with a fresh set of eyes, what
10% tweaks would make this 200% better?`}</li>
      <li parentName="ol">{`Who’s already successfully doing this? What the heck are they doing
differently?`}</li>
      <li parentName="ol">{`Who are 3 smart people i can talk to on the phone to help me problem solve
this?`}</li>
    </ol>
    <p><a parentName="p" {...{
        "href": "https://www.notion.so/Mastering-the-Plateau-ca2e03e580564e55add07baa28e56c1a"
      }}><strong parentName="a">{`I answered all of these questions right here (feel free to duplicate the Notion, so you can use it in the future!)`}</strong></a></p>
    <p><strong parentName="p">{`Then - take massive action on the 3-4 answers that seemed most promising.`}</strong></p>
    <p>{`If you’re here, you’ve done 99% of the work. The last piece is the hardest one.
Patience.`}</p>
    <p>{`I’m an impatient motherfucker in general. And I used to take pride in that.`}</p>
    <p>{`Patient? Who wants to be patient? Patient people just wait around and do nothing
all day.`}</p>
    <p>{`I’m not patient! I take action!`}</p>
    <p>{`^ you see - I viewed `}<strong parentName="p">{`action taking`}</strong>{` and `}<strong parentName="p">{`patience`}</strong>{` as opposites.`}</p>
    <p>{`But then one day, like the modern day buddha I am, I figured it out.`}</p>
    <p>{`A new operating philosophy: `}<strong parentName="p">{`Impatience with action, Patience with results`}</strong>{`.`}</p>
    <p><strong parentName="p">{`This is `}<em parentName="strong">{`the yin and yang of entrepreneurship.`}</em></strong></p>
    <p>{`Never wait to take action. Jump right in. Have `}<strong parentName="p">{`impatience`}</strong>{` for action.`}</p>
    <p>{`But then, be very patient. Play the long game. Do not expect/hope for instant
ramen results. `}<strong parentName="p">{`Patience with results.`}</strong></p>
    <p>{`This combo is unbeatable in entrepreneurship. Winning is just a matter of time.`}</p>
    <p>{`Alright, back to it.`}</p>
    <p>{`-shaan`}</p>
    <Links links={[{
      title: LinkLabel.Template,
      links: [{
        url: 'https://www.facebook.com/TonyRobbins/videos/10152682303459060',
        label: 'Tony Robbins Master, Stresser, Dabbler framework'
      }, {
        url: 'https://www.notion.so/Mastering-the-Plateau-ca2e03e580564e55add07baa28e56c1a',
        label: 'Mastering the Plateau Notion Template'
      }]
    }]} mdxType="Links" />
    <PostFooter prevButton={{
      text: postMap.its_all_about_taking_the_right_risks.label,
      slug: slugify(postMap.its_all_about_taking_the_right_risks.slug)
    }} nextButton={{
      text: postMap.just_the_best_stuff_so_far.label,
      slug: slugify(postMap.just_the_best_stuff_so_far.slug)
    }} mdxType="PostFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      